import { Caret } from '../../styles/collapsible-list.style'
import { DropDownHeaderDiv, DropDownTitle } from '../../styles/records/tutorial.style'

const DropDownHeader = () => {
  return (
    <DropDownHeaderDiv>
      <Caret style={{ cursor: 'pointer' }} />
      <DropDownTitle>Small Tutorial Attendance</DropDownTitle>
    </DropDownHeaderDiv>
  )
}

export default DropDownHeader
