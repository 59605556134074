import React from 'react'

import { Banner } from '../../styles/_app.style'

export const RoundUpMarksWarning = () => {
  return (
    <Banner thin level={'warning'} style={{ marginBottom: '1.5rem' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <strong style={{ textAlign: 'center' }}>
          Please round up all marks to the nearest whole number before entering them below.
          <br />
          Ensure there are no decimal points included.
        </strong>
      </div>
    </Banner>
  )
}
