import { useContext, useEffect, useState } from 'react'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { StudentShortInfo, UserDetails } from '../types/schemas/abc'
import { mapPlainToInstance } from '../utils'
import { useErrorMessage } from './errorMessage.service'

export const useAllStudents = (year: string) => {
  const [allStudents, setAllStudents] = useState<StudentShortInfo[]>([])
  const [allStudentsAreLoaded, setAllStudentsAllLoaded] = useState(false)
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  useEffect(() => {
    axiosInstance
      .get(endpoints.allStudents(year))
      .then(({ data }) => {
        setAllStudents(mapPlainToInstance(StudentShortInfo, data))
      })
      .catch(displayError('Unable to retrieve list of all students'))
      .finally(() => setAllStudentsAllLoaded(true))
  }, [year, axiosInstance, displayError])

  return { allStudents, allStudentsAreLoaded }
}

export const useAllStudentsFull = (year: string, cohorts: string[]) => {
  const [allStudents, setAllStudents] = useState<UserDetails[]>([])
  const [allStudentsAreLoaded, setAllStudentsAllLoaded] = useState(false)
  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  useEffect(() => {
    axiosInstance
      .get(endpoints.studentInfo(year), { params: { cohort: cohorts } })
      .then(({ data }) => {
        setAllStudents(mapPlainToInstance(UserDetails, data))
      })
      .catch(displayError('Unable to retrieve list of all students'))
      .finally(() => setAllStudentsAllLoaded(true))
  }, [year, axiosInstance, displayError, cohorts])

  return { allStudents, allStudentsAreLoaded }
}
