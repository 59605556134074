import {
  ExpandedState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { groupBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import PageHeader from '../../components/common/PageHeader'
import GenericTanStackTableRenderer, {
  createPlainColumn,
} from '../../components/tables/tableRenderer/GenericTanStackTableRenderer'
import { useAllStudentsFull } from '../../hooks/allStudents.service'
import { useExercisesForYear } from '../../hooks/exercises.service'
import { useSubmissionsForYear } from '../../hooks/submissions.service'
import { Banner } from '../../styles/_app.style'
import { Input } from '../../styles/form.style'
import { Main, Section, TableSection } from '../../styles/root.style'
import { formatShortYear } from '../../utils'

type MissingSubmissionRow = {
  login: string
  name: string
  cohort: string
  module: string
  exercise: string
  type: string
}

const RELEVANT_COHORTS = ['c1', 'c2', 'c3', 'c4', 'j1', 'j2', 'j3', 'j4']

const MissingSubmissionsDashboard = () => {
  const { year } = useParams()
  const { allStudents, allStudentsAreLoaded } = useAllStudentsFull(year as string, RELEVANT_COHORTS)
  const { exercises, exercisesAreLoaded } = useExercisesForYear(year as string)
  const { submissions, submissionsAreLoaded } = useSubmissionsForYear(year as string)

  const columnHelper = createColumnHelper<MissingSubmissionRow>()
  const columns = [
    columnHelper.accessor((row) => row.login, {
      id: 'login',
      cell: (info) => (
        <Link
          title={`Link to ${info.getValue()} profile`}
          style={{ textDecoration: 'underline' }}
          to={`/${year as string}/students/${info.getValue()}`}
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Login',
    }),
    columnHelper.accessor((row) => row.name, createPlainColumn<MissingSubmissionRow>('Name')),
    columnHelper.accessor((row) => row.cohort, createPlainColumn<MissingSubmissionRow>('Cohort')),
    columnHelper.accessor((row) => row.module, createPlainColumn<MissingSubmissionRow>('Module')),
    columnHelper.accessor(
      (row) => row.exercise,
      createPlainColumn<MissingSubmissionRow>('Exercise')
    ),
    columnHelper.accessor((row) => row.type, createPlainColumn<MissingSubmissionRow>('Type')),
  ]

  const data = useMemo(() => {
    const submissionLookup = groupBy(
      submissions,
      (submission) => `${submission.exerciseId}-${submission.username}`
    )
    const exerciseLookup = groupBy(exercises, 'moduleCode')

    let result = []
    for (const student of allStudents) {
      const { login, firstname, lastname, cohort, modules } = student
      for (const module of modules ?? []) {
        const { code, level } = module
        if (level === 3) {
          const exercises = exerciseLookup[code] ?? []
          for (const exercise of exercises) {
            const { id, type, number, submissionType } = exercise
            if (['CW', 'PPT', 'PMT', 'MMT'].includes(type))
              if (submissionType === 'individual')
                if (exercise.isClosed)
                  if (submissionLookup[`${id}-${login}`] === undefined)
                    result.push({
                      login,
                      name: `${firstname}-${lastname}`,
                      cohort,
                      module: code,
                      exercise: number.toString(),
                      type,
                    })
          }
        }
      }
    }
    return result
  }, [allStudents, exercises, submissions])

  const [globalFilter, setGlobalFilter] = React.useState('')
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      expanded,
      sorting,
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <Main>
      <PageHeader title="Missing submissions">
        <p>This is an overview of the missing submissions for {formatShortYear(year)}.</p>
      </PageHeader>
      <Section>
        {!(exercisesAreLoaded && submissionsAreLoaded && allStudentsAreLoaded) ? (
          <Banner>Loading data...</Banner>
        ) : (
          !data.length && <Banner>No data to show.</Banner>
        )}
      </Section>
      {exercisesAreLoaded && submissionsAreLoaded && !!data.length && (
        <>
          <Section>
            <Input
              type="text"
              placeholder="Type your filter here..."
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </Section>
          <TableSection expanded>
            <GenericTanStackTableRenderer table={table} size="wide" />
          </TableSection>
        </>
      )}
    </Main>
  )
}

export default MissingSubmissionsDashboard
