import Papa from 'papaparse'
import React, { useEffect, useMemo, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import EditMarkCapDialog from '../components/dialogs/EditMarkCapDialog'
import MarkHistoryDialog from '../components/dialogs/MarkHistoryDialog'
import ExtensionDialog from '../components/exercise/ExtensionDialog'
import { MarkManagementArea } from '../components/exercise/MarkManagementArea'
import { RoundUpMarksWarning } from '../components/exercise/RoundUpMarksWarning'
import RawSubmissionsTable from '../components/tables/RawSubmissionsTable'
import Tooltip from '../components/tooltip/Tooltip'
import { endpoints } from '../constants/endpoints'
import { useUser } from '../contextManagers/user.context'
import {
  DATA_PLACEHOLDER_VALUE,
  EMPTY_CELL_VALUE,
  useExerciseForStaff,
  useSubmissionGroups,
} from '../hooks/exerciseForStaff.service'
import { useMarks } from '../hooks/marks.service'
import { AnchorButton, Banner, Button, Footnote } from '../styles/_app.style'
import { Section, TableSection } from '../styles/root.style'
import { Mapping, RawMarks } from '../types/global'
import { EnrolledStudent, Module, TutorialGroup } from '../types/schemas/abc'
import { Exercise, MarkHistory } from '../types/schemas/emarking'
import { SubmissionDataRow } from '../types/tablesDataRows'
import { toLookupTable } from '../utils'

const MarkingOverview = () => {
  const { year, moduleCode, exerciseNumber } = useParams()
  const { userDetails } = useUser()
  const { module, exercise, enrolledStudentsLookup, setExercise, tutorialGroups } =
    useOutletContext<{
      module: Module
      exercise: Exercise
      enrolledStudentsLookup: Mapping<string, EnrolledStudent>
      setExercise: (newExercise: Exercise) => void
      tutorialGroups: TutorialGroup[]
    }>()

  const { marks, actions } = useMarks(exercise)
  const { groups } = useSubmissionGroups(exercise)
  const markLookup = useMemo(() => toLookupTable(marks, 'student_username'), [marks])

  const {
    tableRows,
    lastSavedMarks,
    setTableRows,
    updateTableWithMarks,
    toggleMarksVisibility,
    postExtension,
  } = useExerciseForStaff(exercise, marks, groups, tutorialGroups, enrolledStudentsLookup)

  const [marksToSave, setMarksToSave] = useState<RawMarks>({})
  const [extensionDialogOpen, setExtensionDialogOpen] = useState(false)
  const [markHistoryDialogOpen, setMarkHistoryDialogOpen] = useState(false)
  const [markHistoryToView, setMarkHistoryToView] = useState<MarkHistory[]>([])

  /* Cap dialog ========================== */
  const [loginToCap, setLoginToCap] = useState<string | undefined>()
  const [capDialogOpen, setCapDialogOpen] = useState(false)
  const applicablePassMark = useMemo(() => {
    if (!loginToCap) return
    const cohort = enrolledStudentsLookup[loginToCap].cohort
    return module.cohortRegulations.find((r) => r.cohort === cohort)?.passMark
  }, [enrolledStudentsLookup, loginToCap, module])
  /* ==================================== */

  const stats = useMemo(
    () => [
      `${Object.keys(enrolledStudentsLookup).length} enrolled`,
      `${
        tableRows.filter((r) => r.latestSubmission !== DATA_PLACEHOLDER_VALUE).length
      } submissions`,
      ...(exercise.isGroup ? [`${tableRows.filter((r) => !!r.subRows).length} groups`] : []),
      `${tableRows.filter((r) => r.mark !== EMPTY_CELL_VALUE).length} marked`,
    ],
    [enrolledStudentsLookup, exercise.isGroup, tableRows]
  )

  function marksFromRows(tableRowsRows: SubmissionDataRow[]): RawMarks {
    return tableRowsRows.reduce((acc, { login, mark, subRows }) => {
      return {
        ...acc,
        [login]: parseInt(`${mark}`),
        ...subRows?.reduce((subAcc, { login, mark }) => {
          return { ...subAcc, [login]: parseInt(`${mark}`) }
        }, {}),
      }
    }, {})
  }

  useEffect(() => {
    if (!lastSavedMarks) return
    setMarksToSave(
      Object.fromEntries(
        Object.entries(marksFromRows(tableRows)).filter(
          ([login, markInTable]) =>
            !isNaN(markInTable) && markInTable !== lastSavedMarks[login]?.mark
        )
      ) as RawMarks
    )
  }, [lastSavedMarks, tableRows])

  function adjustedMark(mark: number, maxMark: number) {
    return Math.min(maxMark, Math.max(0, mark))
  }

  function loadMarksFromCSV(file: File) {
    file
      .text()
      .then((content) => {
        const { data, errors } = Papa.parse(content.trim(), { dynamicTyping: true })
        if (errors.length > 0) console.error(errors)
        else {
          let uploadedMarks = (data as [string, number][])
            .filter(([_, mark]) => mark !== null)
            .map(([login, mark]) => [login, adjustedMark(mark, exercise.maximumMark)])
          updateTableWithMarks(Object.fromEntries(uploadedMarks))
        }
      })
      .catch((error) => {
        console.error('Failed to read CSV file:', error)
      })
  }

  function saveMarks(marks: RawMarks) {
    actions.postMarks(marks).then(() => setMarksToSave({}))
  }

  function viewHistory(history: MarkHistory[]) {
    setMarkHistoryToView(history)
    setMarkHistoryDialogOpen(true)
  }

  function openCapDialog(login: string) {
    setLoginToCap(login)
    setCapDialogOpen(true)
  }

  const marksVisibility = useMemo(() => {
    function handleMarksVisibility(visible: boolean) {
      toggleMarksVisibility(visible).then((exercise) => exercise && setExercise(exercise))
    }

    return {
      published: !!exercise.marksPublished,
      update: handleMarksVisibility,
      userCanUpdate:
        !exercise.locked &&
        (userDetails?.isYearCoordinator ||
          !!userDetails?.isStaffForModule(exercise.moduleCode) ||
          !!userDetails?.isMarkerForModule(exercise.moduleCode)),
    }
  }, [exercise, userDetails])

  return (
    <>
      <Section>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {exercise.locked && (
            <Banner thin level={'danger'}>
              <p>
                This exercise is locked.
                <br />
                No further changes to marks allowed.
              </p>
            </Banner>
          )}
          <div
            style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '0.5rem' }}
          >
            {userDetails?.hasExtensionClearance && (
              <Tooltip label={'Open extension dialog'}>
                <Button onClick={() => setExtensionDialogOpen(true)}>Grant Extension</Button>
              </Tooltip>
            )}
            {!!Object.keys(enrolledStudentsLookup).length && (
              <AnchorButton
                href={endpoints.submissionsZipped(
                  year!,
                  moduleCode!,
                  parseInt(exerciseNumber as string)
                )}
                title="Download raw submissions"
              >
                Bulk download
              </AnchorButton>
            )}
          </div>
          <MarkManagementArea
            marksToSave={marksToSave}
            onMarkFileUpload={loadMarksFromCSV}
            onMarksSave={saveMarks}
            marksVisibility={marksVisibility}
            csvTemplate={{
              data: tableRows
                .flatMap((row) => [row, ...(row.subRows ?? [])])
                .map((r) => [r.login, r.mark])
                .sort(),
              name: `${moduleCode}-${exerciseNumber}-marks.csv`,
            }}
            marksNotForPublication={exercise.marksHiddenToStudents}
          />
        </div>
        <Footnote muted center css={{ margin: '1rem 0 1rem 0' }}>
          {stats.join(' • ')}
        </Footnote>

        <RoundUpMarksWarning />
      </Section>
      <TableSection expanded>
        <RawSubmissionsTable
          onDeleteMark={actions.deleteMark}
          onViewHistory={viewHistory}
          marksUpdateEnabled={!exercise.locked && marksVisibility.userCanUpdate}
          exercise={exercise}
          data={tableRows}
          updateData={setTableRows}
          onOpenCapDialog={openCapDialog}
        />
      </TableSection>
      {loginToCap && markLookup.has(loginToCap) && (
        <EditMarkCapDialog
          mark={markLookup.get(loginToCap)!}
          passMark={applicablePassMark}
          open={capDialogOpen}
          onOpenChange={setCapDialogOpen}
          onSubmit={actions.capMark}
        />
      )}
      <ExtensionDialog
        exercise={exercise}
        enrolledStudents={Object.values(enrolledStudentsLookup)}
        open={extensionDialogOpen}
        onOpenChange={setExtensionDialogOpen}
        onSubmit={postExtension}
      />
      <MarkHistoryDialog
        marks={markHistoryToView}
        open={markHistoryDialogOpen}
        onOpenChange={setMarkHistoryDialogOpen}
      />
    </>
  )
}

export default MarkingOverview
