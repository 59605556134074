import { Accordion, Item } from '@radix-ui/react-accordion'
import React from 'react'
import { Download } from 'react-bootstrap-icons'

import { FileUploadButton } from '../../components/FileUploadButton'
import { RoundUpMarksWarning } from '../../components/exercise/RoundUpMarksWarning'
import { AnchorButton } from '../../styles/_app.style'
import { Caret, Content, Header, Trigger } from '../../styles/collapsible-list.style'
import { Mapping } from '../../types/global'
import { Exercise } from '../../types/schemas/emarking'
import { EmarkingDataRow } from '../../types/tablesDataRows'
import MarkerDistributionTable from './MarkerDistributionTable'

interface MarkingBatchesProps {
  exercise: Exercise
  markingBatches: Mapping<string, EmarkingDataRow[]>
  labelLookup: Mapping<string, string>
  currentUser: string
  distributionDownloadURL: string
  selectionEnabled: boolean
  writeEnabledForAllBatches: boolean
  onSelectionChange: (added: string[], removed: string[]) => void
  onBatchUpdate: (marker: string, batch: EmarkingDataRow[]) => void
  onFeedbackBatchUpload: (marker: string) => (files: FileList) => void
  onFeedbackDelete: (feedbackId: number) => void
  onFeedbackUpload: (submissionId: number) => (files: FileList) => void
  onFeedbackReUpload: (feedbackId: number) => (files: FileList) => void
}

export const MarkingBatches: React.FC<MarkingBatchesProps> = ({
  exercise,
  markingBatches,
  labelLookup,
  currentUser,
  distributionDownloadURL,
  selectionEnabled,
  writeEnabledForAllBatches,
  onSelectionChange,
  onBatchUpdate,
  onFeedbackBatchUpload,
  onFeedbackDelete,
  onFeedbackUpload,
  onFeedbackReUpload,
}: MarkingBatchesProps) => {
  function handleMarkChange(marker: string) {
    return (batch: EmarkingDataRow[]) => onBatchUpdate(marker, batch)
  }

  return (
    <>
      <RoundUpMarksWarning />

      <Accordion type="multiple" defaultValue={[currentUser]}>
        {Object.entries(markingBatches)
          .sort(([markerA], [markerB]) => (labelLookup[markerA] > labelLookup[markerB] ? 1 : -1))
          .map(([marker, batch]) => {
            let markedCount = batch.filter((row) => row.feedbackId !== null).length
            let totalToMark = batch.length
            let writeActionsEnabled =
              !exercise.locked && (writeEnabledForAllBatches || marker === currentUser)
            return (
              <Item value={marker} key={marker}>
                <Header style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <Trigger
                    css={{
                      flexGrow: 2,
                      ...(markedCount < totalToMark
                        ? {
                            backgroundColor: '$amber3',
                            '&:hover': {
                              backgroundColor: '$amber4',
                            },
                          }
                        : {}),
                    }}
                  >
                    <Caret />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <span>{labelLookup[marker] || marker}</span>
                      <span>
                        {markedCount}/{totalToMark}
                      </span>
                    </div>
                  </Trigger>
                  <AnchorButton
                    icon
                    title="Download zipped distribution"
                    href={`${distributionDownloadURL}?marker=${marker}`}
                  >
                    <Download size={22} />
                  </AnchorButton>
                  <FileUploadButton
                    icon
                    directory
                    onFilesReceived={onFeedbackBatchUpload(marker)}
                    disabled={!writeActionsEnabled}
                  />
                </Header>
                <Content>
                  <div style={{ flexGrow: 1, padding: '0 1rem 1rem 1rem', overflowX: 'scroll' }}>
                    <MarkerDistributionTable
                      data={batch}
                      maximumMark={exercise.maximumMark}
                      writeActionsEnabled={writeActionsEnabled}
                      rowSelectionEnabled={selectionEnabled}
                      onMarkChange={handleMarkChange(marker)}
                      onSelectionChange={onSelectionChange}
                      onFeedbackDelete={onFeedbackDelete}
                      onFeedbackUpload={onFeedbackUpload}
                      onFeedbackReUpload={onFeedbackReUpload}
                    />
                  </div>
                </Content>
              </Item>
            )
          })}
      </Accordion>
    </>
  )
}
