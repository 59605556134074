import { isBefore } from 'date-fns'
import Immutable from 'immutable'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useOutletContext } from 'react-router'
import { useParams } from 'react-router-dom'

import { endpoints } from '../constants/endpoints'
import { AxiosContext } from '../contextManagers/axios.context'
import { useGame } from '../contextManagers/game.context'
import { useUser } from '../contextManagers/user.context'
import { concatGrouped, groupByProperty } from '../utils'
import { useErrorMessage } from './errorMessage.service'
import { LevelsManager, groupByLevel } from './levels.service'

/* TODO: Is there any reason that we do not have a complete Resource/Material type */
export type Resource = { tags: string[]; id: number; category: string }
export type LeveledMaterials = GroupedMaterials[]
export type GroupedMaterials = { [key: string]: Resource[] }

type MaterialsManager = {
  groupedMaterials: GroupedMaterials
  setRawMaterials: (rawMaterials: Resource[]) => void
  isLoaded: () => boolean
  noMaterials: () => boolean
  addCompleteResources: (resourceIds: number[]) => void
  copyPreviousYearMaterials: (year: string, course: string) => void
  isComplete: (resourceId: number) => boolean
}

export const useMaterials = ({
  loaded: levelsLoaded,
  selectedLevel,
  hasMinLevels,
  setTotalLevels,
  updateLevel,
}: LevelsManager): MaterialsManager => {
  const { year } = useParams()
  const { userDetails } = useUser()
  const [rawMaterials, setRawMaterials] = useState<Resource[]>([])

  /* Store materials grouped by level to minimise how often we group materials into levels */
  const [leveledMaterials, setLeveledMaterials] = useState<LeveledMaterials>()
  const [unleveledMaterials, setUnleveledMaterials] = useState<GroupedMaterials>({})

  /* Store complete resources to minimise how often we hit the endpoint */
  const [completeResources, setCompleteResources] = useState<Immutable.Set<number>>()

  const axiosInstance = useContext(AxiosContext)
  const displayError = useErrorMessage()

  const { gameSettingOn } = useGame()
  const { moduleCode } = useOutletContext<{ moduleCode: string | null }>()

  const [materialsLoaded, setMaterialsLoaded] = useState(false)
  const groupedMaterials = useMemo(
    () =>
      gameSettingOn && !userDetails?.isStaff && hasMinLevels
        ? concatGrouped(unleveledMaterials, leveledMaterials?.at(selectedLevel - 1) ?? {})
        : (groupByProperty(rawMaterials, 'category', 'index', true) as GroupedMaterials),
    [gameSettingOn, rawMaterials, leveledMaterials, unleveledMaterials, selectedLevel, hasMinLevels]
  )
  useEffect(() => {
    setMaterialsLoaded(false)
    axiosInstance
      .get(endpoints.resources, { params: { year, course: moduleCode } })
      .then(({ data }) => {
        // TODO: this needs improving as soon as we use a proper class transformer for Resource
        let visibleData =
          userDetails?.isStaff || userDetails?.isGTAForModule(moduleCode!)
            ? data
            : data.filter((d: any) => isBefore(new Date(d.visible_after), new Date()))
        setRawMaterials(visibleData)
        setMaterialsLoaded(true)
      })
      .catch(displayError('Failed to fetch resources'))
  }, [axiosInstance, displayError, moduleCode, year])

  function isLoaded(): boolean {
    return materialsLoaded && (!gameSettingOn || levelsLoaded)
  }

  function noMaterials() {
    return Object.keys(groupedMaterials).length === 0
  }

  function isComplete(resourceId: number): boolean {
    return completeResources?.contains(resourceId) ?? false
  }

  async function addCompleteResources(resourceIds: number[]) {
    setCompleteResources(completeResources?.union<number>(resourceIds))
    axiosInstance
      .post(endpoints.resourcesComplete, resourceIds)
      .catch(displayError('Failed to mark resources as complete'))
  }

  async function copyPreviousYearMaterials(year: string, course: string) {
    const currYearStart = parseInt(year.slice(0, 2), 10)
    const previousYear = `${currYearStart - 1}${currYearStart}`
    const payload = { year_from: previousYear, year_to: year }

    axiosInstance
      .post(endpoints.copyResources(course), payload)
      .then(({ data }) => setRawMaterials(data))
      .catch(displayError(`No resources exist for year ${payload.year_from}`))
  }

  /* Group materials by category */
  useEffect(() => {
    if (gameSettingOn && !userDetails?.isStaff) {
      const [newLeveledMaterials, newUnleveledMaterials] = groupByLevel(rawMaterials)
      setTotalLevels(newLeveledMaterials.length)
      setUnleveledMaterials(newUnleveledMaterials)
      setLeveledMaterials(newLeveledMaterials)
    }
  }, [gameSettingOn, rawMaterials, setTotalLevels])

  /* Fetch complete resources */
  useEffect(() => {
    if (gameSettingOn && !userDetails?.isStaff) {
      axiosInstance
        .get(endpoints.resourcesComplete, { params: { module_code: moduleCode } })
        .then(({ data }) => {
          setCompleteResources(Immutable.Set<number>(data))
        })
        .catch(displayError('Failed to fetch complete resources'))
    }
  }, [gameSettingOn, axiosInstance, moduleCode, displayError])

  /* Update the user level and progress */
  useEffect(() => {
    if (gameSettingOn && !userDetails?.isStaff && leveledMaterials && completeResources) {
      updateLevel(leveledMaterials, completeResources)
    }
  }, [gameSettingOn, completeResources, leveledMaterials, updateLevel])

  return {
    groupedMaterials,
    setRawMaterials,
    isLoaded,
    noMaterials,
    copyPreviousYearMaterials,
    addCompleteResources,
    isComplete,
  }
}
