import { PersonCircle } from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'

import { useProfileImage } from '../../hooks/profileImage.service'
import {
  ProfileCardDiv,
  ProfileCardImageDiv,
  ProfileCardInfo,
  ProfileCardInnerContainer,
} from '../../styles/records/profileCard.style'
import { UserDetails } from '../../types/schemas/abc'
import { ProfileImage } from './ProfileImage'

const ProfileCard = ({ studentDetails }: { studentDetails: UserDetails }) => {
  const { year } = useParams()
  const { imageBlob } = useProfileImage(year!, studentDetails.login)

  const imageAlt = `Image of ${studentDetails.firstname} ${studentDetails.lastname}`

  return (
    <ProfileCardDiv>
      <ProfileCardInnerContainer>
        <ProfileCardImageDiv>
          {imageBlob ? (
            <ProfileImage image={imageBlob} imageAlt={imageAlt} size={8}></ProfileImage>
          ) : (
            <PersonCircle size={120} />
          )}
        </ProfileCardImageDiv>
        <ProfileCardInfo>
          <div>
            <b>
              {studentDetails.firstname} {studentDetails.lastname} ({studentDetails.login})
            </b>
          </div>
          <div>
            <b>CID:</b> {studentDetails.cid}
          </div>
          <div>
            <b>Department: </b> CO
          </div>
          <div>
            <b>Category: </b>
            {studentDetails.cohortName}
          </div>
          <div>
            <b>Email: </b>
            <span
              style={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                textDecoration: 'underline',
              }}
            >
              <a href={`mailto:${studentDetails.email}`}>{studentDetails.email}</a>
            </span>
          </div>
          {studentDetails.personalTutor && (
            <div>
              <b>Personal Tutor: </b>
              {studentDetails.personalTutor.firstname} {studentDetails.personalTutor.lastname} (
              {studentDetails.personalTutor.login})
            </div>
          )}
        </ProfileCardInfo>
      </ProfileCardInnerContainer>
    </ProfileCardDiv>
  )
}

export default ProfileCard
